import React, { Suspense, lazy } from "react"
import Amplify from "aws-amplify"
import Auth from "@aws-amplify/auth"
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifySignOut,
  AmplifyForgotPassword
} from "@aws-amplify/ui-react"

// Handling AWS errors in a more user friendly way - custom toast
import Toast from "./components/Toast"
import { toast } from "react-toastify"
import { Hub, HubCallback } from "@aws-amplify/core"
import { AuthState, onAuthUIStateChange, UI_AUTH_CHANNEL, TOAST_AUTH_ERROR_EVENT } from "@aws-amplify/ui-components"

import "./css/main.css"
import "./css/rowcolumn.css"
import { Role } from "./components/role.js"
import aws_exports from "./aws-exports"

import banner from "./images/banner.png"
import Header from "./components/Header"
import Loading from "./components/Loading"
import FooterwSocial from "./components/FooterwSocial"

// IMPORTANT!
// This is called "code-splitting" and it improves page rendering as only the components
// (code) is rendered when needed. The <Loading/> component is shown as needed when rendering in process.
const Admin = lazy(() => import("./Admin"))
const PreQual = lazy(() => import("./PreQual"))
const Coach = lazy(() => import("./Coach"))
const Home = lazy(() => import("./Home"))
const Locations = lazy(() => import("./Locations"))

const UnityRoom = lazy(() => import("./unity/unityRoom"))

const Analysis = lazy(() => import("./Analysis"))
const Scoreboard = lazy(() => import("./Scoreboard"))
//const Register = lazy(() => import("./Register"))
const Unassigned = lazy(() => import("./Unassigned"))
const Unknown = lazy(() => import("./Unknown"))

// Maintain browser history for app
const history = createBrowserHistory()

// All three required to configure now with 2021 update
Amplify.configure(aws_exports)
Auth.configure(aws_exports)
toast.configure()

/*
  ) : // Registration page is available with AND without authentication
  pathname === "/register" ? (
    <div>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <Route exact path="/register" component={Register} />
        </Suspense>
      </Router>
    </div>
*/

const App = () => {
  const [authState, setAuthState] = React.useState()
  const [user, setUser] = React.useState()
  const [role, setRole] = React.useState()
  const unityRooms = ["/hackerHideout", "/controlCenter", "/mainOffice", "/serverRoom", "/ralphsApartment", "/darlenesApartment", "/ryansApartment", "/mobileLab", "/practice"]; //update with available unity rooms

  // Handling AWS errors in a more user friendly way - toast you can always see
  // https://fkhadra.github.io/react-toastify/introduction
  const handleToastErrors: HubCallback = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
      toast("❌ " + payload.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }

  // Maintain current path location of user
  const pathname = window.location.pathname

  // Handling AWS errors in a more user friendly way - using Hub to listen for errors
  React.useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors)
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors)
  })

  // When authentication state changes
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
      if (authData !== undefined && nextAuthState === AuthState.SignedIn) {
        setRole(authData.signInUserSession.accessToken.payload["cognito:groups"])
      }
    })
  }, [])

  // All pages available when user is successfully signed in
  return authState === AuthState.SignedIn && user && role ? (
    <div>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          {!unityRooms.includes(pathname) ? <Header role={role} /> : <span></span>}

          {/* Switch executes in the order of the Routes
             therefore Unknown is last to catch any page that doesn't first match the others defined */}
          <Switch>
            <Route exact path="/admin" component={role.includes(Role.Admin) ? Admin : Unknown} />
            <Route
              exact
              path="/prequal"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? PreQual : Unknown}
            />
            <Route exact path="/coach" component={role.includes(Role.Coach) ? Coach : Unknown} />
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/locations"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? Locations : Unknown}
            />
            <Route
              exact
              path="/hackerHideout"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"hackerHideout"} sceneName={"Hacker Hideout"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/controlCenter"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"controlCenter"} sceneName={"Control Center"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/mainOffice"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"mainOffice"} sceneName={"Main Office"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/mobileLab"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"mobileLab"} sceneName={"Mobile Lab"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/serverRoom"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"serverRoom"} sceneName={"Server Room"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/ralphsApartment"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"ralphsApartment"} sceneName={"Ralph's Apartment"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/darlenesApartment"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"darlenesApartment"} sceneName={"Darlene's Apartment"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/ryansApartment"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? () => <UnityRoom awsName={"ryansApartment"} sceneName={"Ryan's Apartment"} public={false} /> : Unknown}
            />
            <Route
              exact
              path="/analysis"
              component={role.includes(Role.Admin) || role.includes(Role.Student) ? Analysis : Unknown}
            />
            <Route
              exact
              path="/practice"
              component={() => <UnityRoom awsName={"practice"} sceneName={"Practice Room"} public={true} />}
            />
            <Route exact path="/scoreboard" component={Scoreboard} />
            <Route component={Unknown} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  ) : // User is signed in, but there was an issue when assigning their role (Cognito group) so they are "Unassigned"
    authState === AuthState.SignedIn && user ? (
      <div>
        <Router history={history}>
          <Suspense fallback={<Loading />}>
            {/* Switch executes in the order of the Routes
             therefore Unknown is last to catch any page that doesn't first match the others defined */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/scoreboard" component={Scoreboard} />
              <Route component={Unassigned} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    ) : // Confirm sign up page -- custom!
      authState === AuthState.ConfirmSignUp ? (
        <div>
          <section className="home-light">
            <h2 className="section-title-light">Check your email for a verification link!</h2>
          </section>
        </div>
      ) : // Scoreboard page is available with AND without authentication (PUBLIC)
        pathname === "/scoreboard" || pathname === "/practice" ? (
          <div>
            <Router history={history}>
              <Suspense fallback={<Loading />}>
                {pathname === "/scoreboard" && <section className="home-footer" style={{ paddingTop: "0", paddingBottom: "0" }}>
                  <a href="/"><img src={banner} width="100%" alt="Welcome logo" /></a>
                </section>}

                <Switch>
                  {pathname === "/scoreboard" ?
                    <Route exact path="/scoreboard" component={Scoreboard} /> :
                    <Route exact path="/practice" component={() => <UnityRoom awsName={"practice"} sceneName={"Practice Room"} public={true} />}
                    />}
                  <Route component={Unknown} />
                </Switch>
              </Suspense>
            </Router>
          </div>
        ) : (
          // Display authentication page when user is not signed in or looking at scoreboard
          <div>
            <section className="home-light">
              {/*https://fkhadra.github.io/react-toastify/introduction*/}
              <Toast />
              <div className="row column large-10">
                <h2 className="section-title-light">
                  Welcome to the California Cyber Innovation Challenge - Mission Apgartium!
                </h2>
                <h2 className="section-subtitle-light center-align">Provided by the California Cybersecurity Institute</h2>
                <p className="content center-align">
                  To access the challenge, you will need to create an account and click the verification link you receive via
                  email. Then, you can sign in using the email and password created.{" "}
                  <b>
                    Questions? Contact us via Discord with the CCIC Help Bot:{" "}
                    <span className="highlight">!help [technical] &#60;your question&#62;</span>
                  </b>
                  <br />
                  <br />
                </p>
                {/* https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#props-slots-amplify-authenticator */}
                <AmplifyAuthenticator
                  hideToast
                  usernameAlias="email"
                  style={{ "--container-align": "flex-start", "--container-height": "auto" }}>
                  <AmplifySignUp
                    slot="sign-up"
                    usernameAlias="email"
                    headerText="Create Credentials Below"
                    formFields={[
                      {
                        type: "email",
                        label: "Email Address*",
                        placeholder: "Enter the email provided during registration",
                        required: true
                      },
                      {
                        type: "password",
                        label: "Password*",
                        hint: "Requirements: length >= 8, uppercase letter, lowercase letter, number, special character",
                        placeholder: "Enter your password of choice",
                        required: true
                      }
                    ]}
                  />
                  <AmplifySignIn
                    slot="sign-in"
                    usernameAlias="email"
                    headerText="Enter Credentials Below"
                    formFields={[
                      {
                        type: "email",
                        label: "Email Address*",
                        required: true
                      },
                      {
                        type: "password",
                        label: "Password*",
                        required: true
                      }
                    ]}
                  />
                  <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" sendButtonText="Request Reset" />
                  <AmplifySignOut />
                </AmplifyAuthenticator>
              <p className="content center-align">Looking to do some practice? Check out the <a href="/practice">Hideout Practice Room</a></p>
              </div>
            </section>

            <footer className="home-footer">
              <FooterwSocial lightFooter={false} />
            </footer>
          </div>
        )
}

export default App
