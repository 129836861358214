import { Component } from "react"
import PropTypes from "prop-types"
import { AmplifySignOut } from "@aws-amplify/ui-react"

import { Role } from "./role.js"
import banner from "../images/banner.png"

export default class Header extends Component {
  render() {
    return (
      <section className="home-header" style={{ paddingTop: "0", paddingBottom: "0" }}>
        <a href="/"><img src={banner} width="100%" alt="Welcome logo" /></a>
        <div className="row column large-10">
          <table width="100%">
            {/* Display this header for ADMIN roles */}
            {this.props.role.includes(Role.Admin) && (
              <tbody>
                <tr>
                  <td width="15%">
                    <a href="/admin" className="menu">
                      Admin
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/prequal" className="menu">
                      Pre-Qualification
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/locations" className="menu">
                      Locations
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/analysis" className="menu">
                      Analysis
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/scoreboard" target="_blank" className="menu">
                      Scoreboard
                    </a>
                  </td>
                  <td width="10%">
                    <AmplifySignOut />
                  </td>
                </tr>
              </tbody>
            )}

            {/* Display this header for STUDENT roles */}
            {this.props.role.includes(Role.Student) && (
              <tbody>
                <tr>
                  <td width="20%">
                    <a href="/prequal" className="menu">
                      Pre-Qualification
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/locations" className="menu">
                      Locations
                    </a>
                  </td>
                  <td width="15%">
                    <a href="/analysis" className="menu">
                      Analysis
                    </a>
                  </td>
                  <td width="20%">
                    <a href="/scoreboard" target="_blank" className="menu">
                      Scoreboard
                    </a>
                  </td>
                  <td width="15%">
                    <AmplifySignOut />
                  </td>
                </tr>
              </tbody>
            )}

            {/* Display this header for COACH roles */}
            {this.props.role.includes(Role.Coach) && (
              <tbody>
                <tr>
                  <td width="5%"></td>
                  <td width="20%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="25%">
                    <a href="/coach" className="menu">
                      Coach Dashboard
                    </a>
                  </td>
                  <td width="25%">
                    <a href="/scoreboard" target="_blank" className="menu">
                      Scoreboard
                    </a>
                  </td>
                  <td width="20%">
                    <AmplifySignOut />
                  </td>
                  <td width="5%"></td>
                </tr>
              </tbody>
            )}

            {/* Display this header for UNASSIGNED roles */}
            {this.props.role.includes(Role.Unassigned) && (
              <tbody>
                <tr>
                  <td width="25%"></td>
                  <td width="20%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="20%">
                    <a href="/scoreboard" target="_blank" className="menu">
                      Scoreboard
                    </a>
                  </td>
                  <td width="10%">
                    <AmplifySignOut />
                  </td>
                  <td width="25%"></td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </section>
    )
  }
}

Header.propTypes = {
  role: PropTypes.array.isRequired
}
