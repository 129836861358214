/* eslint-disable */
/* prettier-ignore */

const aws_exports = {
	"Auth": {
		"region": "us-east-1",
		"userPoolId": "us-east-1_GqWUpInmN",
		"userPoolWebClientId": "14tt9gf16acmb3dvd34bqiqebr"
	},
	// S3 bucket for uploading Admin CSVs
	"Storage": {
		"AWSS3": {
			"bucket": "ccic-2021-uploadusers",
			"region": "us-west-2"
		}
	},
	// Add in new API, "name" can be whatever one wants
	"API": {
		"endpoints": [
			{
				"name": "code",
				"endpoint": "https://apf3c9xrgc.execute-api.us-west-2.amazonaws.com/prod"
			}
		]
	},

	"aws_project_region": "us-east-1",
	"aws_cognito_identity_pool_id": "us-east-1:e43db8e1-ddd2-464e-98cb-1c2c86790cb6",
	"aws_cognito_region": "us-east-1",
	"aws_user_pools_id": "us-east-1_GqWUpInmN",
	"aws_user_pools_web_client_id": "14tt9gf16acmb3dvd34bqiqebr",
	"oauth": {}
};

export default aws_exports
