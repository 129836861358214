import { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faYoutube, faFacebook, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

export default class FooterwSocial extends Component {
  render() {
    return (
      <div>
        <div className="row column large-10">
          Copyright &copy; 2020 - {new Date().getFullYear()} CA Cyber -{" "}
          <a href="https://cci.calpoly.edu/" title="https://cci.calpoly.edu/" target="_blank" rel="noopener noreferrer">
            California Cybersecurity Institute
          </a>
          . All Rights Reserved.
          <br />
          <br />
          <a
            href="https://www.youtube.com/channel/UCk6d1JaQPyuEoQ3dSr4nurg"
            title="https://www.youtube.com/channel/UCk6d1JaQPyuEoQ3dSr4nurg"
            className="social">
            <FontAwesomeIcon
              icon={faYoutube}
              size="2x"
              style={{ color: this.props.lightFooter ? "#eb3223" : "#FFFFFF" }}
            />
          </a>
          <a
            href="https://www.facebook.com/CalPolyCCI/"
            title="https://www.facebook.com/CalPolyCCI/"
            className="social">
            <FontAwesomeIcon
              icon={faFacebook}
              size="2x"
              style={{ color: this.props.lightFooter ? "#4968ad" : "#FFFFFF" }}
            />
          </a>
          <a
            href="https://www.instagram.com/calpolycci/"
            title="https://www.instagram.com/calpolycci/"
            className="social">
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              style={{ color: this.props.lightFooter ? "#000000" : "#FFFFFF" }}
            />
          </a>
          <a href="https://twitter.com/CalPolyCCI" title="https://twitter.com/CalPolyCCI" className="social">
            <FontAwesomeIcon
              icon={faTwitter}
              size="2x"
              style={{ color: this.props.lightFooter ? "#49a1eb" : "#FFFFFF" }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/cal-poly-cci/"
            title="https://www.linkedin.com/company/cal-poly-cci/"
            className="social">
            <FontAwesomeIcon
              icon={faLinkedin}
              size="2x"
              style={{ color: this.props.lightFooter ? "#0e76a8" : "#FFFFFF" }}
            />
          </a>
        </div>
      </div>
    )
  }
}

FooterwSocial.propTypes = {
  lightFooter: PropTypes.bool.isRequired
}
